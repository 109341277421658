
import Vue from 'vue';
import getCountryFlag from 'country-flag-icons/unicode';
import enCountries from 'i18n-iso-countries/langs/en.json';

import { composedPath } from 'Lib/utils/eventPathChecker';
import Search from 'Control/Search.vue';
import Icon from 'UI/Icon.vue';

type Data = {
    enCountries: any;
    getCountryFlag: any;
    showDropdown: boolean;
    searchValue: string;
    selectedCountry: {
        code: string;
        name: string;
    };
};

type Methods = {
    closeSearch: (data: boolean) => void;
    selectCountry: (data: string) => void;
    closeTooltip: (data: any) => void;
};

type Computed = {
    filteredCountriesList: string[];
};

type Props = {
    title: string;
    defaultCountry?: string;
};

export default Vue.extend<Data, Methods, Computed, Props>({
    components: { Icon, Search },
    props: {
        title: {
            type: String,
            required: true,
        },
        defaultCountry: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            enCountries,
            getCountryFlag,
            showDropdown: false,
            searchValue: '',
            selectedCountry: {
                code: '',
                name: '',
            },
        };
    },
    computed: {
        filteredCountriesList() {
            if (!this.searchValue) {
                return Object.keys(this.enCountries.countries);
            }
            return Object.keys(this.enCountries.countries).filter((c) => {
                return Array.isArray(enCountries.countries[c])
                    ? enCountries.countries[c][0].toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1
                    : enCountries.countries[c].toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1;
            });
        },
    },
    methods: {
        closeSearch(value) {
            if (!value) {
                this.searchValue = '';
            }
        },
        selectCountry(country) {
            this.selectedCountry.code = country;
            this.selectedCountry.name = Array.isArray(this.enCountries.countries[country]) ? this.enCountries.countries[country][0] : this.enCountries.countries[country];
            this.$emit('select-country', { ...this.selectedCountry });
            this.showDropdown = false;
            this.searchValue = '';
        },
        closeTooltip(e) {
            let flag = true;
            const path = composedPath(e.target);
            if (path) {
                path.forEach((el) => {
                    if (el.classList && Array.from(el.classList).includes('country-input')) {
                        flag = false;
                    }
                });
                if (flag) {
                    this.showDropdown = false;
                    this.searchValue = '';
                }
            }
        },
    },
    mounted() {
        [this.selectedCountry.code] = Object.keys(enCountries.countries);
        this.selectedCountry.name = this.enCountries.countries[Object.keys(enCountries.countries)[0]];
        if (this.defaultCountry) {
            const countryCode = Object.keys(enCountries.countries)
                .find((c) => (
                    Array.isArray(enCountries.countries[c])
                        ? enCountries.countries[c][0] === this.defaultCountry
                        : enCountries.countries[c] === this.defaultCountry
                ));
            if (countryCode) {
                this.selectedCountry.code = countryCode;
                this.selectedCountry.name = Array.isArray(enCountries.countries[countryCode]) ? enCountries.countries[countryCode][0] : enCountries.countries[countryCode];
            }
        }
        this.$emit('select-country', { ...this.selectedCountry });

        document.addEventListener('click', this.closeTooltip);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.closeTooltip);
    },
    watch: {
        searchValue() {
            this.$forceUpdate();
        },
    },
});
