// This file was autogenerated. Please do not change.
export interface ICreateBankRequisitesRequestData {
    alias: string;
    asset: string;
    beneficiaryAccountIdentifier: string;
    beneficiaryAddress?: string;
    beneficiaryBankAddress?: string;
    beneficiaryBankCountry: string;
    beneficiaryBankCountryCode: string;
    beneficiaryBankIdentifier: string;
    beneficiaryBankName?: string;
    beneficiaryCountry: string;
    beneficiaryCountryCode: string;
    beneficiaryName: string;
    emailTotp: string;
    isActive?: boolean;
    paymentNetwork: ('swift' | 'sepa');
    totp: string;
}

export default class CreateBankRequisitesRequestData {
    readonly _alias: string;

    /** Description: Alias for requisites */
    get alias(): string {
        return this._alias;
    }

    readonly _asset: string;

    /** Description: Requisite currency */
    get asset(): string {
        return this._asset;
    }

    readonly _beneficiaryAccountIdentifier: string;

    /** Description: Beneficiary Account Identifier. Can be account number of IBAN */
    get beneficiaryAccountIdentifier(): string {
        return this._beneficiaryAccountIdentifier;
    }

    readonly _beneficiaryAddress: string | undefined;

    /** Description: Beneficiary address */
    get beneficiaryAddress(): string | undefined {
        return this._beneficiaryAddress;
    }

    readonly _beneficiaryBankAddress: string | undefined;

    /** Description: Beneficiary bank address */
    get beneficiaryBankAddress(): string | undefined {
        return this._beneficiaryBankAddress;
    }

    readonly _beneficiaryBankCountry: string;

    /** Description: Beneficiary bank country */
    get beneficiaryBankCountry(): string {
        return this._beneficiaryBankCountry;
    }

    readonly _beneficiaryBankCountryCode: string;

    /** Description: Beneficiary bank country code in aplha2 format */
    get beneficiaryBankCountryCode(): string {
        return this._beneficiaryBankCountryCode;
    }

    readonly _beneficiaryBankIdentifier: string;

    /** Description: Beneficiary Bank Identifier. Can be SWIFT(BIC) code or sort code */
    get beneficiaryBankIdentifier(): string {
        return this._beneficiaryBankIdentifier;
    }

    readonly _beneficiaryBankName: string | undefined;

    /** Description: Beneficiary bank Name */
    get beneficiaryBankName(): string | undefined {
        return this._beneficiaryBankName;
    }

    readonly _beneficiaryCountry: string;

    /** Description: Beneficiary country */
    get beneficiaryCountry(): string {
        return this._beneficiaryCountry;
    }

    readonly _beneficiaryCountryCode: string;

    /** Description: Beneficiary country code in aplha2 format */
    get beneficiaryCountryCode(): string {
        return this._beneficiaryCountryCode;
    }

    readonly _beneficiaryName: string;

    /** Description: Beneficiary Name */
    get beneficiaryName(): string {
        return this._beneficiaryName;
    }

    readonly _emailTotp: string;

    /** Description: Email TOTP verification code */
    get emailTotp(): string {
        return this._emailTotp;
    }

    readonly _isActive: boolean | undefined;

    /** Description: True if recepient was created in bank provider */
    get isActive(): boolean | undefined {
        return this._isActive;
    }

    readonly _paymentNetwork: ('swift' | 'sepa');

    /** Description: Payment network for this requisites */
    get paymentNetwork(): ('swift' | 'sepa') {
        return this._paymentNetwork;
    }

    readonly _totp: string;

    /** Description: TOTP verification code */
    get totp(): string {
        return this._totp;
    }

    constructor(props: ICreateBankRequisitesRequestData) {
        this._alias = props.alias.trim();
        this._asset = props.asset.trim();
        this._beneficiaryAccountIdentifier = props.beneficiaryAccountIdentifier.trim();
        if (typeof props.beneficiaryAddress === 'string') {
            this._beneficiaryAddress = props.beneficiaryAddress.trim();
        }
        if (typeof props.beneficiaryBankAddress === 'string') {
            this._beneficiaryBankAddress = props.beneficiaryBankAddress.trim();
        }
        this._beneficiaryBankCountry = props.beneficiaryBankCountry.trim();
        this._beneficiaryBankCountryCode = props.beneficiaryBankCountryCode.trim();
        this._beneficiaryBankIdentifier = props.beneficiaryBankIdentifier.trim();
        if (typeof props.beneficiaryBankName === 'string') {
            this._beneficiaryBankName = props.beneficiaryBankName.trim();
        }
        this._beneficiaryCountry = props.beneficiaryCountry.trim();
        this._beneficiaryCountryCode = props.beneficiaryCountryCode.trim();
        this._beneficiaryName = props.beneficiaryName.trim();
        this._emailTotp = props.emailTotp.trim();
        if (typeof props.isActive === 'boolean') {
            this._isActive = props.isActive;
        }
        this._paymentNetwork = props.paymentNetwork;
        this._totp = props.totp.trim();
    }

    serialize(): ICreateBankRequisitesRequestData {
        const data: ICreateBankRequisitesRequestData = {
            alias: this._alias,
            asset: this._asset,
            beneficiaryAccountIdentifier: this._beneficiaryAccountIdentifier,
            beneficiaryBankCountry: this._beneficiaryBankCountry,
            beneficiaryBankCountryCode: this._beneficiaryBankCountryCode,
            beneficiaryBankIdentifier: this._beneficiaryBankIdentifier,
            beneficiaryCountry: this._beneficiaryCountry,
            beneficiaryCountryCode: this._beneficiaryCountryCode,
            beneficiaryName: this._beneficiaryName,
            emailTotp: this._emailTotp,
            paymentNetwork: this._paymentNetwork,
            totp: this._totp,
        };
        if (typeof this._beneficiaryAddress !== 'undefined') {
            data.beneficiaryAddress = this._beneficiaryAddress;
        }
        if (typeof this._beneficiaryBankAddress !== 'undefined') {
            data.beneficiaryBankAddress = this._beneficiaryBankAddress;
        }
        if (typeof this._beneficiaryBankName !== 'undefined') {
            data.beneficiaryBankName = this._beneficiaryBankName;
        }
        if (typeof this._isActive !== 'undefined') {
            data.isActive = this._isActive;
        }
        return data;
    }

    toJSON(): ICreateBankRequisitesRequestData {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            alias: typeof this._alias === 'string' && !!this._alias.trim(),
            asset: typeof this._asset === 'string' && !!this._asset.trim(),
            beneficiaryName: typeof this._beneficiaryName === 'string' && !!this._beneficiaryName.trim(),
            beneficiaryAccountIdentifier: typeof this._beneficiaryAccountIdentifier === 'string' && !!this._beneficiaryAccountIdentifier.trim(),
            beneficiaryBankIdentifier: typeof this._beneficiaryBankIdentifier === 'string' && !!this._beneficiaryBankIdentifier.trim(),
            beneficiaryCountry: typeof this._beneficiaryCountry === 'string' && !!this._beneficiaryCountry.trim(),
            beneficiaryCountryCode: typeof this._beneficiaryCountryCode === 'string' && !!this._beneficiaryCountryCode.trim(),
            beneficiaryAddress: !this._beneficiaryAddress ? true : typeof this._beneficiaryAddress === 'string',
            beneficiaryBankName: !this._beneficiaryBankName ? true : typeof this._beneficiaryBankName === 'string',
            beneficiaryBankCountry: typeof this._beneficiaryBankCountry === 'string' && !!this._beneficiaryBankCountry.trim(),
            beneficiaryBankAddress: !this._beneficiaryBankAddress ? true : typeof this._beneficiaryBankAddress === 'string',
            beneficiaryBankCountryCode: typeof this._beneficiaryBankCountryCode === 'string' && !!this._beneficiaryBankCountryCode.trim(),
            isActive: !this._isActive ? true : typeof this._isActive === 'boolean',
            totp: typeof this._totp === 'string' && !!this._totp.trim(),
            emailTotp: typeof this._emailTotp === 'string' && !!this._emailTotp.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
